const makeScope = (ns, endpoint = '*') => [ns, endpoint].join('.');

const expandScope = (scope) => {
  const scopeParts = scope.split('.');

  const ns = scopeParts[0];
  const endpoint = scopeParts[1];

  return { ns, endpoint };
};

const matchingScopes = (sourceScopes, seekScope) =>
  sourceScopes
    .map(expandScope)
    .reduce((acc, expandedScope) => {
      if (expandedScope.ns !== seekScope.ns) return [...acc, false];

      if (
        expandedScope.endpoint !== seekScope.endpoint &&
        expandedScope.endpoint !== '*'
      ) {
        return [...acc, false];
      }

      return [...acc, true];
    }, [])
    .some((result) => !!result);

export { expandScope, matchingScopes };

export default makeScope;
