'use client';

import { array, node } from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useNotifications, useSuspensions } from '@personly/libs-providers';
import { Form } from '@personly/libs-widgets';

import { useTickets as useTicketsService } from '../hooks';

const TicketsContext = createContext();

const TicketsProvider = ({ children, combinations }) => {
  const [email, setEmail] = useState('');
  const [body, setBody] = useState('');

  const { createNotification } = useNotifications();
  const { blocked } = useSuspensions();

  const { createTicket } = useTicketsService();

  const type = useMemo(() => combinations[0], [combinations]);

  const isDisabled = useMemo(
    () => !email || !body || !type || blocked,
    [email, body, blocked, type]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      createTicket({ email, body, type }, (code) => {
        setBody('');
        setEmail('');

        createNotification('SUCCESS', code, {}, Infinity);
      });
    },
    [createNotification, body, createTicket, email, type]
  );

  const value = { isDisabled, email, setEmail, body, setBody };

  return (
    <TicketsContext.Provider value={value}>
      <Form onSubmit={handleSubmit}>{children}</Form>
    </TicketsContext.Provider>
  );
};

function useTickets() {
  const context = useContext(TicketsContext);
  if (context === undefined) throw new Error('useTickets requires a context!');

  return context;
}

TicketsProvider.propTypes = {
  children: node.isRequired,
  combinations: array.isRequired,
};

export { TicketsProvider, useTickets };
