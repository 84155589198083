'use client';

import { node } from 'prop-types';
import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

import { useCookie, useTld } from '@personly/libs-hooks';

import { useReferralCodes } from '../hooks';

const ReferralsProvider = ({ children }) => {
  const searchParams = useSearchParams();
  const tld = useTld();
  const cookie = useCookie();
  const { referralCode, getReferralCode } = useReferralCodes();

  useEffect(() => {
    if (!searchParams.has('referral')) return;
    getReferralCode(searchParams.get('referral'));
  }, [searchParams, getReferralCode]);

  useEffect(() => {
    (async () => {
      if (referralCode) {
        const domain = await tld.getDomain(window.location.hostname);
        await cookie.set('referral', referralCode.id, { domain, expires: 365 });
      }
    })();
  }, [referralCode, cookie, tld]);

  return children;
};

ReferralsProvider.propTypes = { children: node };

export default ReferralsProvider;
