'use client';

import { node } from 'prop-types';

import { StreamsProvider } from '@personly/libs-providers';
import makeScope from '@personly/libs-scopes';

const url = process.env.NEXT_PUBLIC_STREAM_GUEST_URL;
const scope = makeScope(process.env.NEXT_PUBLIC_ROLE_GUEST);

const Provider = ({ children }) => (
  <StreamsProvider scope={scope} url={url}>
    {children}
  </StreamsProvider>
);

Provider.propTypes = { children: node.isRequired };

export default Provider;
